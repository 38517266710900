import React from 'react';

import { SectionAirco, SectionArea, SectionCalendar, SectionColor, SectionContact, SectionInsulation, SectionOverview, SectionPrevious, SectionSpace, SectionStart, SectionThankyou, SectionType, SectionUnitExternal } from './Section';

function loadstate(key)
{
    let session_value = sessionStorage.getItem(key);
    if (session_value !== null && session_value !== undefined)
        return session_value;

    return null;
}

function PageAirco(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/isolatie' 
                translations_data={props.translations_data} />

            <SectionAirco
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_images={props.upload_images} />
        </React.Fragment>
    );
}

function PageArea(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/ruimtes' 
                translations_data={props.translations_data} />

            <SectionArea
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageCalendar(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/'
                translations_data={props.translations_data} />

            <SectionCalendar
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageColor(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/airco'
                translations_data={props.translations_data} />

            <SectionColor 
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_images={props.upload_images} />
        </React.Fragment>
    );
}

function PageContact(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                //previous='/extras'
                previous='/buitenunit'
                translations_data={props.translations_data} />

            <SectionContact 
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>   
    )
}

/*
function PageExtra(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/buitenunit' 
                translations_data={props.translations_data} />

            <SectionExtra
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}
*/

function PageInsulation(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/oppervlaktes' 
                translations_data={props.translations_data} />

            <SectionInsulation
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageOverview(props)
{
    console.log('page-overview');
    
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/contact' 
                translations_data={props.translations_data} />

            <SectionOverview
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageSpace(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/type' 
                translations_data={props.translations_data} />

            <SectionSpace
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageStart(props)
{
    return (
        <React.Fragment>
            <SectionStart
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />

            <div className="content-copyright">
                ©{new Date().getFullYear()}. Applicatie door <a className="content-copyright-link" href="https://thissen.ai" target="_blank" rel="noreferrer">Thissen AI</a>®
            </div>
        </React.Fragment>
    );
}

function PageThankyou(props)
{
    return (
        <SectionThankyou
            debug={props.debug}
            ip_address={props.ip_address}
            translations_data={props.translations_data}
            upload_images={props.upload_images} />
    );
}

function PageType(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/plaatsing' 
                translations_data={props.translations_data} />

            <SectionType
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageUnitExternal(props)
{
    const configurator_airco = loadstate('configurator_airco');

    return (
        <React.Fragment>
            { configurator_airco !== 'etherea' &&
                <SectionPrevious
                    previous='/airco'
                    translations_data={props.translations_data} />
            }

            { configurator_airco === 'etherea' &&
                <SectionPrevious
                    previous='/kleur' 
                    translations_data={props.translations_data} />
            }

            <SectionUnitExternal
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

export { PageAirco, PageArea, PageCalendar, PageColor, PageContact, PageInsulation, PageOverview, PageSpace, PageStart, PageThankyou, PageType, PageUnitExternal }