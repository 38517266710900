import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ElementHeaderH1, ElementStatus } from '../components/Element';

import { UtilTranslate } from './Util';

function loadstate(key)
{
    let session_value = sessionStorage.getItem(key);
    if (session_value !== null && session_value !== undefined)
        return session_value;

    return null;
}

function loadstatearray(key, default_array)
{
    let session_value = sessionStorage.getItem(key);
    if (session_value !== null && session_value !== undefined)
        return JSON.parse(session_value);
    
    return default_array;
}

function loadstateboolean(key)
{
    let session_value = sessionStorage.getItem(key);
    if (session_value === 'true')
        return true;

    return false;
}

function savestate(key, value)
{
    // Save (when value exists)
    if (value !== null && value !== undefined)
        sessionStorage.setItem(key, value);
}

function savestatearray(key, value)
{
    // Save (when value exists)
    if (value !== null && value !== undefined)
        sessionStorage.setItem(key, JSON.stringify(value));
}

function nulltoblank(value)
{
    if (value === null)
        return '';

    return value;
}

function nulltofalse(value)
{
    if (value === null)
        return false;

    return value;
}

function select(ip_address, key, value)
{
    // Payload
    const data = {
        'key': key,
        'value': value
    };

    // Post and handle error
    axios.post(ip_address+'/backend/rechargedairconditioners/api/select', data).catch(function (error) {
        console.log(error);
    });
}

// Props: debug, ip_address, translations_data, upload_images
function SectionAirco(props)
{
    // Configurator
    const [configurator_airco, configurator_set_airco] = useState(loadstate('configurator_airco'));

    useEffect(() => {
        savestate('configurator_airco', configurator_airco);
    }, [
        configurator_airco
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'airco', configurator_airco);

        if (configurator_airco !== 'etherea')
            navigate('/buitenunit');
        else
            navigate('/kleur');
    }

    // Options 4 (airco)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option4_airco');
                
                options_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_airco === null)
                    configurator_set_airco(response.data[1][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_airco]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-producttile noselect';
            if (configurator_airco === options_data[i][1])
            {
                buttonclass = 'form-radio-producttile form-radio-producttile-selected noselect';
            }

            options.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}>

                        <input
                            type="radio"
                            className="form-radio-hidden"
                            id={options_data[i][1]}
                            name="type"
                            value={options_data[i][1]}
                            checked={configurator_airco === options_data[i][1]}
                            onChange={event => {
                                configurator_set_airco(event.target.value);
                            }} />
                        
                        <div className="form-radio-producttile-title-container">
                            <div className="form-radio-producttile-title">
                                {options_data[i][0]}
                            </div>

                            <div className="form-radio-producttile-subtitle">
                                {options_data[i][2]}
                            </div>

                            { options_data[i][3] === true &&
                                <div className="form-radio-producttile-highlight">
                                    {UtilTranslate(props.translations_data, 'airco_topchosen', props.debug)}
                                </div>
                            }
                        </div>
                        
                        <div className="form-radio-producttile-description-container">
                            <div className="form-radio-producttile-description-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(options_data[i][4])}}></div>
                            <div className="form-radio-producttile-description-image" style={{backgroundImage: 'url('+props.upload_images+'/'+options_data[i][6]+')'}}></div>
                        </div>
                        
                        { options_data[i][5] !== null &&
                            <div className="content-info-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('<div class="content-info-icon"></div>'+options_data[i][5])}}></div>
                        }
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'airco_title', props.debug)} />
                                
                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'airco_preference', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-producttile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionArea(props)
{
    // Configurator
    const configurator_space_name = loadstatearray('configurator_space_name');
    const [configurator_space_area, configurator_set_space_area] = useState(loadstatearray('configurator_space_area', [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]));

    useEffect(() => {
        savestatearray('configurator_space_area', configurator_space_area);
    }, [
        configurator_space_area,
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        for (let i = 0; i < 16; i++)
        {
            if (configurator_space_area[i] !== '' && configurator_space_area[i] !== null)
                select(props.ip_address, 'space_area', configurator_space_area[i].substring(0, 32));
        }

        navigate('/isolatie');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'area',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    let form = [];
    let key = 0;
    for (let i = 0; i < 16; i++)
    {
        if (configurator_space_name[i] !== '' && configurator_space_name[i] !== null)
        {
            let label = UtilTranslate(props.translations_data, 'area_space', props.debug)+'<div class="form-label-small-required">*</div>';
            if (label !== undefined)
            {
                label = label.replace('$space', configurator_space_name[i]);
            }

            form.push(
                <React.Fragment key={key}>
                    <label htmlFor={'space_area'+(1+i)} className="form-label-small" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label)}}></label>
                    <input
                        className="form-text"
                        id={'space_area'+(1+i)}
                        type="number"
                        min="0"
                        max="100000000"
                        step="0.01"
                        value={nulltoblank(configurator_space_area[i])}
                        autoComplete="off"
                        onChange={event => {
                            let space_area = [...configurator_space_area];
                            space_area[i] = event.target.value;

                            configurator_set_space_area(space_area);
                        }}
                        required />
                </React.Fragment>
            );

            key++;
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'area_title', props.debug)} />

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'area_surface', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    {form}

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionCalendar(props)
{
    // Configurator
    const [configurator_calendar, configurator_set_calendar] = useState(loadstate('configurator_calendar'));

    useEffect(() => {
        savestate('configurator_calendar', configurator_calendar);
    }, [
        configurator_calendar
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'calendar', configurator_calendar);

        navigate('/type');
    }

    // Options 1 (calendar)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option1_calendar');
                // <str> option title
                // <int> option value
                // <str> option icon

                options_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_calendar === null)
                    configurator_set_calendar(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_calendar]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-tile';
            if (configurator_calendar === options_data[i][1])
            {
                buttonclass = 'form-radio-tile form-radio-tile-selected';
            }

            options.push(
                <React.Fragment key={i}>
                    <input
                        type="radio"
                        className="form-radio-hidden"
                        id={options_data[i][1]}
                        name="calendar"
                        value={options_data[i][1]}
                        checked={configurator_calendar === options_data[i][1]}
                        onChange={event => {
                            configurator_set_calendar(event.target.value);
                        }} />
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}
                        style={{flexBasis: Math.floor(100/options_data.length)+'%'}}>
                        <div className="form-radio-tile-icon" style={{backgroundImage: 'url('+props.upload_icons+'/'+options_data[i][2]+')'}}>
                        </div>
                        <div className="form-radio-tile-label noselect">
                            <div className="form-radio-tile-label-text">
                                {options_data[i][0]}
                            </div>
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'calendar_title', props.debug)} />

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'calendar_when', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-tile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_images
function SectionColor(props)
{
    // Configurator
    const [configurator_color, configurator_set_color] = useState(loadstate('configurator_color'));

    useEffect(() => {
        savestate('configurator_color', configurator_color);
    }, [
        configurator_color
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'color', configurator_color);

        navigate('/buitenunit');
    }

    // Options 5 (airco)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option5_color');
                // <str> option title
                // <int> option value
                // <str> option image

                options_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_color === null)
                    configurator_set_color(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_color]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-tile';
            if (configurator_color === options_data[i][1])
            {
                buttonclass =  'form-radio-tile form-radio-tile-selected';
            }

            options.push(
                <React.Fragment key={i}>
                    <input
                        type="radio"
                        className="form-radio-hidden"
                        id={options_data[i][1]}
                        name="color"
                        value={options_data[i][1]}
                        checked={configurator_color === options_data[i][1]}
                        onChange={event => {
                            configurator_set_color(event.target.value);
                        }} />
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}
                        style={{flexBasis: Math.floor(100/options_data.length)+'%'}}>
                        <div className="form-radio-tile-icon" style={{backgroundImage: 'url('+props.upload_images+'/'+options_data[i][2]+')'}}>
                        </div>
                        <div className="form-radio-tile-label noselect">
                            <div className="form-radio-tile-label-text">
                                {options_data[i][0]}
                            </div>
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'color_title', props.debug)} />

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'color_preference', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-tile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionContact(props)
{   
    // Configurator
    const configurator_type = loadstate('configurator_type');
    const [configurator_contact_companyname, configurator_set_contact_companyname] = useState(loadstate('configurator_contact_companyname'));
    const [configurator_contact_name, configurator_set_contact_name] = useState(loadstate('configurator_contact_name'));
    const [configurator_contact_street, configurator_set_contact_street] = useState(loadstate('configurator_contact_street'));
    const [configurator_contact_streetnumber, configurator_set_contact_streetnumber] = useState(loadstate('configurator_contact_streetnumber'));
    const [configurator_contact_postalcode, configurator_set_contact_postalcode] = useState(loadstate('configurator_contact_postalcode'));
    const [configurator_contact_city, configurator_set_contact_city] = useState(loadstate('configurator_contact_city'));
    const [configurator_contact_email, configurator_set_contact_email] = useState(loadstate('configurator_contact_email'));
    const [configurator_contact_phone, configurator_set_contact_phone] = useState(loadstate('configurator_contact_phone'));
    const [configurator_contact_kvk, configurator_set_contact_kvk] = useState(loadstate('configurator_contact_kvk'));
    const [configurator_contact_btw, configurator_set_contact_btw] = useState(loadstate('configurator_contact_btw'));
    const [configurator_contact_legal, configurator_set_contact_legal] = useState(loadstateboolean('configurator_contact_legal'));

    useEffect(() => {
        savestate('configurator_contact_companyname', configurator_contact_companyname);
        savestate('configurator_contact_name', configurator_contact_name);
        savestate('configurator_contact_street', configurator_contact_street);
        savestate('configurator_contact_streetnumber', configurator_contact_streetnumber);
        savestate('configurator_contact_postalcode', configurator_contact_postalcode);
        savestate('configurator_contact_city', configurator_contact_city);
        savestate('configurator_contact_email', configurator_contact_email);
        savestate('configurator_contact_phone', configurator_contact_phone);
        savestate('configurator_contact_kvk', configurator_contact_kvk);
        savestate('configurator_contact_btw', configurator_contact_btw);
        savestate('configurator_contact_legal', configurator_contact_legal);
    }, [
        configurator_contact_companyname,
        configurator_contact_name,
        configurator_contact_street,
        configurator_contact_streetnumber,
        configurator_contact_postalcode,
        configurator_contact_city,
        configurator_contact_email,
        configurator_contact_phone,
        configurator_contact_kvk,
        configurator_contact_btw,
        configurator_contact_legal
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {

        // Event
        window.dataLayer = window.dataLayer || [];
        if (configurator_type === 'business')
        {
            window.dataLayer.push({
                event: 'form_complete',
                form_id: 'contactform',
                form_data: {
                    'companyname': configurator_contact_companyname,
                    'name': configurator_contact_name,
                    'street': configurator_contact_street,
                    'streetnumber': configurator_contact_streetnumber,
                    'postalcode': configurator_contact_postalcode,
                    'city': configurator_contact_city,
                    'email': configurator_contact_email,
                    'phone': configurator_contact_phone,
                    'kvk': configurator_contact_kvk,
                    'btw': configurator_contact_btw
                }
            });
        }
        else
        {
            window.dataLayer.push({
                event: 'form_complete',
                form_id: 'contactform',
                form_data: {
                    'name': configurator_contact_name,
                    'street': configurator_contact_street,
                    'streetnumber': configurator_contact_streetnumber,
                    'postalcode': configurator_contact_postalcode,
                    'city': configurator_contact_city,
                    'email': configurator_contact_email,
                    'phone': configurator_contact_phone
                }
            });
        }

        navigate('/samenvatting');

        event.preventDefault();
    }
    
    // Event
    const [event_started, event_set_started] = useState(false);
    const handleFocus = (event) => {
        if (event_started === false)
        {
            event_set_started(true);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'form_start',
                form_id: 'contactform'
            });
        }
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'contact',
                        'legal'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null && props.translations_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'contact_title', props.debug)} />

                                <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <form id="contactform" onSubmit={event => handleSubmit(event)}>
                                    { configurator_type === 'business' &&
                                        <React.Fragment>
                                            <label htmlFor="companyname" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_companyname', props.debug)}<div className="form-label-small-required">*</div></label>
                                            <input
                                                className="form-text"
                                                type="text"
                                                id="companyname"
                                                maxLength="128"
                                                value={nulltoblank(configurator_contact_companyname)}
                                                onChange={event => {
                                                    configurator_set_contact_companyname(event.target.value);
                                                }}
                                                required />
                                        </React.Fragment>
                                    }

                                    <label htmlFor="name" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_name', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="name"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_name)}
                                        onChange={event => {
                                            configurator_set_contact_name(event.target.value);
                                        }}
                                        onFocus={handleFocus}
                                        required />

                                    <label htmlFor="street" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_street', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="street"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_street)}
                                        onChange={event => {
                                            configurator_set_contact_street(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="number" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_number', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="streetnumber"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_streetnumber)}
                                        onChange={event => {
                                            configurator_set_contact_streetnumber(event.target.value);
                                        }}
                                        required />


                                    <label htmlFor="postalcode" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_postalcode', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="postalcode"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_postalcode)}
                                        onChange={event => {
                                            configurator_set_contact_postalcode(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="city" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_city', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="city"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_city)}
                                        onChange={event => {
                                            configurator_set_contact_city(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="email" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_email', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="email"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_email)}
                                        onChange={event => {
                                            configurator_set_contact_email(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="phone" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_phone', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="phone"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_phone)}
                                        onChange={event => {
                                            configurator_set_contact_phone(event.target.value);
                                        }}
                                        required />

                                    { configurator_type === 'business' &&
                                        <React.Fragment>
                                            <label htmlFor="kvk" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_kvk', props.debug)}<div className="form-label-small-required">*</div></label>
                                            <input
                                                className="form-text"
                                                type="text"
                                                id="kvk"
                                                maxLength="128"
                                                value={nulltoblank(configurator_contact_kvk)}
                                                onChange={event => {
                                                    configurator_set_contact_kvk(event.target.value);
                                                }}
                                                required />

                                            <label htmlFor="btw" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_btw', props.debug)}</label>
                                            <input
                                                className="form-text"
                                                type="text"
                                                id="btw"
                                                maxLength="128"
                                                value={nulltoblank(configurator_contact_btw)}
                                                onChange={event => {
                                                    configurator_set_contact_btw(event.target.value);
                                                }} />
                                        </React.Fragment>
                                    }

                                    <div className="form-check form-switch">
                                        <label htmlFor="legal" className="form-label-small">
                                            <input
                                                className="form-check-input form-toggle"
                                                type="checkbox"
                                                id="legal"
                                                checked={nulltofalse(configurator_contact_legal)}
                                                onChange={event => configurator_set_contact_legal(event.target.checked)}
                                                required />
                                            <div className="form-toggle-tex noselect" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1], { ADD_ATTR: ['target'] }) }}></div>
                                        </label>
                                    </div>

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'contact_submit', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
/*
function SectionExtra(props)
{
    // Configurator
    const [configurator_dampening, configurator_set_dampening] = useState(loadstate('configurator_dampening'));
    const [configurator_pipeline, configurator_set_pipeline] = useState(loadstate('configurator_pipeline'));

    useEffect(() => {
        savestate('configurator_dampening', configurator_dampening);
        savestate('configurator_pipeline', configurator_pipeline);
    }, [
        configurator_dampening,
        configurator_pipeline
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'dampening', configurator_dampening);
        select(props.ip_address, 'pipeline', configurator_pipeline);
        
        navigate('/contact');
    }

    // Options 6 (dampening)
    const [options_dampening_data, options_set_dampening_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option6_dampening');
                // <str> title
                // <str> value

                options_set_dampening_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let options_dampening = [];
    if (options_dampening_data !== null)
    {
        for (let i = 0; i < options_dampening_data.length; i++)
        {
            options_dampening.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={'dampening-'+options_dampening_data[i][1]}
                        className="form-radio-text-label">
                        
                        <input
                            type="radio"
                            className="form-radio-text-button"
                            id={'dampening-'+options_dampening_data[i][1]}
                            name={'dampening-'+options_dampening_data[i][1]}
                            value={options_dampening_data[i][1]}
                            checked={configurator_dampening === options_dampening_data[i][1]}
                            onChange={event => {
                                configurator_set_dampening(event.target.value);
                            }} />

                        <div className="form-radio-text-input">
                            {options_dampening_data[i][0]}
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    // Options 6 (pipeline)
    const [options_pipeline_data, options_set_pipeline_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option6_pipeline');
                // <str> title
                // <str> value

                options_set_pipeline_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let options_pipeline = [];
    if (options_pipeline_data !== null)
    {
        for (let i = 0; i < options_pipeline_data.length; i++)
        {
            options_pipeline.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={'pipeline-'+options_pipeline_data[i][1]}
                        className="form-radio-text-label">
                        
                        <input
                            type="radio"
                            className="form-radio-text-button"
                            id={'pipeline-'+options_pipeline_data[i][1]}
                            name={'pipeline-'+options_pipeline_data[i][1]}
                            value={options_pipeline_data[i][1]}
                            checked={configurator_pipeline === options_pipeline_data[i][1]}
                            onChange={event => {
                                configurator_set_pipeline(event.target.value);
                            }} />

                        <div className="form-radio-text-input">
                            {options_pipeline_data[i][0]}
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'extra',
                        'dampening',
                        'pipeline'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'extra_title', props.debug)} />

                                <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <form onSubmit={event => handleSubmit(event)}>

                                    <fieldset
                                        className="form-radio-text-container">
                                        <div className="form-label">
                                            {UtilTranslate(props.translations_data, 'extra_dampening', props.debug)}
                                        </div>
                                        
                                        <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1]) }}></div>
                                        
                                        {options_dampening}
                                    </fieldset>
                                    
                                    <fieldset
                                        className="form-radio-text-container">
                                        <div className="form-label">
                                            {UtilTranslate(props.translations_data, 'extra_pipeline', props.debug)}
                                        </div>

                                        <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[2]) }}></div>

                                        {options_pipeline}
                                    </fieldset>

                                    { configurator_dampening !== null && configurator_pipeline !== null &&
                                        <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                    }
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
*/

// Props: debug, ip_address, translations_data, upload_icons
function SectionInsulation(props)
{
    // Configurator
    const [configurator_insulation, configurator_set_insulation] = useState(loadstate('configurator_insulation'));

    useEffect(() => {
        savestate('configurator_insulation', configurator_insulation);
    }, [
        configurator_insulation
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'insulation', configurator_insulation);

        navigate('/airco');
    }

    // Options 3 (insulation)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option3_insulation');
                // <str> option title
                // <int> option value
                // <str> option icon

                options_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_insulation === null)
                    configurator_set_insulation(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_insulation]);
    
    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-tile';
            if (configurator_insulation === options_data[i][1])
            {
                buttonclass =  'form-radio-tile form-radio-tile-selected';
            }

            options.push(
                <React.Fragment key={i}>
                    <input
                        type="radio"
                        className="form-radio-hidden"
                        id={options_data[i][1]}
                        name="insulation"
                        value={options_data[i][1]}
                        checked={configurator_insulation === options_data[i][1]}
                        onChange={event => {
                            configurator_set_insulation(event.target.value);
                        }} />
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}
                        style={{flexBasis: Math.floor(100/options_data.length)+'%'}}>
                        <div className="form-radio-tile-icon" style={{backgroundImage: 'url('+props.upload_icons+'/'+options_data[i][2]+')'}}>
                        </div>
                        <div className="form-radio-tile-label noselect">
                            <div className="form-radio-tile-label-text">
                                {options_data[i][0]}
                            </div>
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'insulation_title', props.debug)} />
                                
                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'insulation_howgood', props.debug)}
                                </div>
                                
                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-tile-container">
                                        {options}
                                    </fieldset>

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, percentage, translations_data, upload_images
function SectionNavigation(props)
{
    // Image
    const [image_data, image_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    key: 'googlereviews'
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/image', { params });
                // <str> image
                
                image_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error) 
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container">
                    <Navbar variant="light" className="navbar-container">
                        <div className="navbar-title-container">
                            <Link to="https://www.recharged.nl" className="navbar-title" target="_blank"></Link>
                            <div className="d-none d-md-block navbar-title-seperator"></div>
                            <div className="d-none d-md-block navbar-subtitle">{UtilTranslate(props.translations_data, 'title', props.debug)}</div>
                        </div>

                        { image_data !== null &&
                            <div className="navbar-reviews" style={{backgroundImage: 'url('+props.upload_images+'/'+image_data+')'}}></div>
                        }
                    </Navbar>
                </div>
                <ElementStatus
                    percentage={props.percentage} />
            </section>
            <section className="navbar-spacing"></section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translations_data
function SectionOverview(props)
{
    // Configurator
    const configurator_calendar = loadstate('configurator_calendar');
    const configurator_type = loadstate('configurator_type');
    const configurator_space_name = useRef(loadstatearray('configurator_space_name', [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null])); // Create reference, this is required since this will be passed into useEffect dependency array
    const configurator_space_area = useRef(loadstatearray('configurator_space_area', [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null])); // Create reference, this is required since this will be passed into useEffect dependency array
    const configurator_insulation = loadstate('configurator_insulation');
    const configurator_airco = loadstate('configurator_airco');
    const configurator_color = loadstate('configurator_color');
    const configurator_unitexternal = loadstate('configurator_unitexternal');
    //const configurator_dampening = loadstate('configurator_dampening');
    //const configurator_pipeline = loadstate('configurator_pipeline');

    const configurator_contact_companyname = loadstate('configurator_contact_companyname');
    const configurator_contact_name = loadstate('configurator_contact_name');
    const configurator_contact_street = loadstate('configurator_contact_street');
    const configurator_contact_streetnumber = loadstate('configurator_contact_streetnumber');
    const configurator_contact_postalcode = loadstate('configurator_contact_postalcode');
    const configurator_contact_city = loadstate('configurator_contact_city');
    const configurator_contact_email = loadstate('configurator_contact_email');
    const configurator_contact_phone = loadstate('configurator_contact_phone');
    const configurator_contact_kvk = loadstate('configurator_contact_kvk');
    const configurator_contact_btw = loadstate('configurator_contact_btw');
    
    // Form
    const [form_loading, form_set_loading] = useState(true);
    const [form_error, form_set_error] = useState(false);
    
    // Overview
    const [overview_data, overview_set_data] = useState(null);
    useEffect(() => {
        const post_overview = async() => {
            try
            {
                let space_name = [];
                let space_area = [];
                for (let i = 0; i < 16; i++)
                {
                    if (configurator_space_name.current[i] !== '' && configurator_space_name.current[i] !== null)
                    {
                        space_name.push(configurator_space_name.current[i]);
                        space_area.push(parseInt(configurator_space_area.current[i]));
                    }
                }

                const data = {
                    'name': configurator_contact_name,
                    'street': configurator_contact_street,
                    'streetnumber': configurator_contact_streetnumber,
                    'postalcode': configurator_contact_postalcode,
                    'city': configurator_contact_city,
                    'email': configurator_contact_email,
                    'phone': configurator_contact_phone,
                    'calendar': configurator_calendar,
                    'type': configurator_type,
                    'space_name': space_name,
                    'space_area': space_area,
                    'insulation': configurator_insulation,
                    'airco': configurator_airco,
                    'unitexternal': configurator_unitexternal
                    //'dampening': configurator_dampening,
                    //'pipeline': configurator_pipeline
                };

                if (configurator_type === 'business')
                {
                    Object.assign(data, {
                        'companyname': configurator_contact_companyname,
                        'kvk': configurator_contact_kvk
                    });
                    
                    if (configurator_contact_btw !== '' && configurator_contact_btw !== null)
                    {
                        Object.assign(data, {
                            'btw': configurator_contact_btw
                        });
                    }
                }
                
                if (configurator_airco === 'etherea')
                {
                    Object.assign(data, {
                        'color': configurator_color,
                    });
                }

                const response = await axios.post(props.ip_address+'/backend/rechargedairconditioners/api/overview', data);
                // <str> calendar title
                // <str> type title
                // <str> insulation title
                // <str> airco title
                // <str> color title
                // <str> price
                
                overview_set_data(response.data);
                form_set_loading(false);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                form_set_loading(false);
                form_set_error(true);

                console.log(error);
            }
        }

        post_overview();
    }, [props.debug, props.ip_address,

        configurator_calendar, configurator_type, configurator_space_name, configurator_space_area, configurator_insulation, configurator_airco, configurator_color, configurator_unitexternal,

        configurator_contact_companyname, configurator_contact_name, configurator_contact_street, configurator_contact_streetnumber, configurator_contact_postalcode, configurator_contact_city, configurator_contact_email, configurator_contact_phone, configurator_contact_kvk, configurator_contact_btw
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        form_set_loading(true);
        form_set_error(false);

        const post_conversion = async() => {
            try
            {
                let space_name = [];
                let space_area = [];
                for (let i = 0; i < 16; i++)
                {
                    if (configurator_space_name.current[i] !== '' && configurator_space_name.current[i] !== null)
                    {
                        space_name.push(configurator_space_name.current[i]);
                        space_area.push(parseInt(configurator_space_area.current[i]));
                    }
                }

                const data = {
                    'name': configurator_contact_name,
                    'street': configurator_contact_street,
                    'streetnumber': configurator_contact_streetnumber,
                    'postalcode': configurator_contact_postalcode,
                    'city': configurator_contact_city,
                    'email': configurator_contact_email,
                    'phone': configurator_contact_phone,
                    'calendar': configurator_calendar,
                    'type': configurator_type,
                    'space_name': space_name,
                    'space_area': space_area,
                    'insulation': configurator_insulation,
                    'airco': configurator_airco,
                    'unitexternal': configurator_unitexternal,
                    //'dampening': configurator_dampening,
                    //'pipeline': configurator_pipeline
                };

                if (configurator_type === 'business')
                {
                    Object.assign(data, {
                        'companyname': configurator_contact_companyname,
                        'kvk': configurator_contact_kvk
                    });
                    
                    if (configurator_contact_btw !== '' && configurator_contact_btw !== null)
                    {
                        Object.assign(data, {
                            'btw': configurator_contact_btw
                        });
                    }
                }

                if (configurator_airco === 'etherea')
                {
                    Object.assign(data, {
                        'color': configurator_color,
                    });
                }

                const response = await axios.post(props.ip_address+'/backend/rechargedairconditioners/api/conversion', data);

                if (props.debug === true)
                    console.log(response.data);
                
                select(props.ip_address, 'conversion', '-');

                navigate('/bedankt');
            }

            catch (error)
            {
                form_set_loading(false);
                form_set_error(true);

                console.log(error);
            }
        }

        post_conversion();
    }
    
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'overview',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text

                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Spaces
    let spaces = [];
    for (let i = 0; i < 16; i++)
    {
        if (configurator_space_name.current[i] !== '' && configurator_space_name.current[i] !== null)
        {
            let area = UtilTranslate(props.translations_data, 'overview_area', props.debug);
            if (area !== undefined)
            {
                area = area.replace('$total', configurator_space_area.current[i]);
            }
            
            spaces.push(
                <tr key={i}>
                    <td className="content-overview-table-cell-left">
                        {UtilTranslate(props.translations_data, 'overview_space', props.debug)}
                    </td>
                    <td className="content-overview-table-cell-right">
                        {configurator_space_name.current[i]+' '+area}
                    </td>
                </tr>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { form_loading === true &&
                            <div className="content-paragraph">
                                <div className="form-loading-container">
                                    <div className="form-loading"></div>
                                </div>
                            </div>
                        }

                        { form_loading === false && form_error === true && props.translations_data !== null &&
                            <div className="content-error-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(UtilTranslate(props.translations_data, 'form_error', props.debug)) }}>
                            </div>
                        }

                        { content_data !== null && form_loading === false && form_error === false &&
                            <div className="content-overview-container">
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'overview_title', props.debug)} />

                                <div className="content-overview-subcontainer">
                                    <div className="content-overview-subcontainer-header">
                                        <div className="content-overview-title">
                                            {UtilTranslate(props.translations_data, 'overview_yourchoices', props.debug)}
                                        </div>
                                    </div>
                                    
                                    <table className="content-overview-table">
                                        <tbody>
                                            <tr>
                                                <td className="content-overview-table-cell-left-top">
                                                    {UtilTranslate(props.translations_data, 'overview_calendar', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right-top">
                                                    {overview_data[0]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_type', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[1]}
                                                </td>
                                            </tr>

                                            {spaces}

                                            <tr>
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_insulation', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[2]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_airco', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[3]}
                                                </td>
                                            </tr>
                                            
                                            { overview_data[4] !== null &&
                                                <tr>
                                                    <td className="content-overview-table-cell-left">
                                                        {UtilTranslate(props.translations_data, 'overview_color', props.debug)}
                                                    </td>
                                                    <td className="content-overview-table-cell-right">
                                                        {overview_data[4]}
                                                    </td>
                                                </tr>
                                            }

                                            <tr>
                                                <td className="content-overview-table-cell-left-bottom">
                                                    {UtilTranslate(props.translations_data, 'overview_unitexternal', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right-bottom">
                                                    {configurator_unitexternal}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="content-overview-subcontainer-text">
                                        {UtilTranslate(props.translations_data, 'overview_capability', props.debug)}
                                    </div>

                                    <div className="content-overview-subcontainer-text">
                                        {UtilTranslate(props.translations_data, 'overview_extras', props.debug)}
                                    </div>

                                    <div className="content-overview-subcontainer-footer">
                                        {UtilTranslate(props.translations_data, 'overview_indication', props.debug)}
                                        
                                        <div className="content-overview-price">
                                            <div className="d-block d-sm-none">
                                                { configurator_type === 'private' &&
                                                    <React.Fragment>
                                                        <div className="content-overview-price-title-mobile">€ {Math.round(overview_data[5]*1.21)}</div>
                                                        <div className="content-overview-price-text">{UtilTranslate(props.translations_data, 'overview_inbtw', props.debug)}</div>
                                                    </React.Fragment>
                                                }

                                                { configurator_type === 'business' &&
                                                    <React.Fragment>
                                                        <div className="content-overview-price-title-mobile">€ {overview_data[5]}</div>
                                                        <div className="content-overview-price-text">{UtilTranslate(props.translations_data, 'overview_exbtw', props.debug)}</div>
                                                    </React.Fragment>
                                                }
                                            </div>

                                            <div className="d-none d-sm-block">
                                                { configurator_type === 'private' &&
                                                    <React.Fragment>
                                                        <div className="content-overview-price-title-desktop">€ {Math.round(overview_data[5]*1.21)}</div>
                                                        <div className="content-overview-price-text">{UtilTranslate(props.translations_data, 'overview_inbtw', props.debug)}</div>
                                                    </React.Fragment>
                                                }

                                                { configurator_type === 'business' &&
                                                    <React.Fragment>
                                                        <div className="content-overview-price-title-desktop">€ {overview_data[5]}</div>
                                                        <div className="content-overview-price-text">{UtilTranslate(props.translations_data, 'overview_exbtw', props.debug)}</div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        
                                        { /*
                                        <div className="content-overview-subcontainer-text-extras">{UtilTranslate(props.translations_data, 'overview_extra2', props.debug)}</div>

                                        <table className="content-overview-table-extras">
                                            <tbody>
                                                <tr>
                                                    <td className="content-overview-table-cell-left-top">
                                                        {UtilTranslate(props.translations_data, 'overview_dampening', props.debug)}
                                                    </td>
                                                    <td className="content-overview-table-cell-right-top">
                                                        {overview_data[5]}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="content-overview-table-cell-left-bottom">
                                                        {UtilTranslate(props.translations_data, 'overview_pipeline', props.debug)}
                                                    </td>
                                                    <td className="content-overview-table-cell-right-bottom">
                                                        {overview_data[6]}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        */ }

                                        <div className="content-overview-title">{UtilTranslate(props.translations_data, 'overview_conversion', props.debug)}</div>
                                        
                                        <form onSubmit={event => handleSubmit(event)}>
                                            <button onClick = {() => {}} className="form-submit-overview"><span>{UtilTranslate(props.translations_data, 'form_send', props.debug)}</span></button>
                                        </form>
                                        
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                        <div className="content-overview-installq-logo"></div>

                                        <div className="content-overview-installq-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(UtilTranslate(props.translations_data, 'overview_installq', props.debug)) }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-info-container">
                                    <div className="content-info-icon"></div>
                                    {UtilTranslate(props.translations_data, 'overview_disclaimer', props.debug)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: translations_data
function SectionPrevious(props)
{
    return (
        <section>
            <div className="container">
                <div className="row">
                    <Link
                        className="navbar-previous"
                        to={props.previous}>
                        <div className="navbar-previous-icon"></div>
                        {UtilTranslate(props.translations_data, 'form_previous', props.debug)}
                    </Link>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionSpace(props)
{
    // Configurator
    const [configurator_space_name, configurator_set_space_name] = useState(loadstatearray('configurator_space_name', [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]));

    useEffect(() => {
        savestatearray('configurator_space_name', configurator_space_name);
    }, [
        configurator_space_name,
    ]);
    
    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        for (let i = 0; i < 16; i++)
        {
            if (configurator_space_name[i] !== '' && configurator_space_name[i] !== null)
                select(props.ip_address, 'space_name', configurator_space_name[i].substring(0, 32));
        }

        navigate('/oppervlaktes');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'space',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Determine the last input in the array that contains input
    let index_last = 0;
    for (let i = 0; i < 16; i++)
    {
        if (configurator_space_name[i] !== '' && configurator_space_name[i] !== null)
            index_last = i;
    }

    let form = [];
    let key = 0;
    for (let i = 0; i < 16; i++)
    {
        if (i === 0)
        {
            form.push(
                <React.Fragment key={key}>
                    <label htmlFor={'space_name'+(1+i)} className="form-label-small">{UtilTranslate(props.translations_data, 'space_name', props.debug)} {1+i}<div className="form-label-small-required">*</div></label>
                    <input
                        className="form-text"
                        type="text"
                        id={'space_name'+(1+i)}
                        maxLength="128"
                        value={nulltoblank(configurator_space_name[i])}
                        onChange={event => {
                            let space_name = [...configurator_space_name];
                            space_name[i] = event.target.value;

                            configurator_set_space_name(space_name);
                        }}
                        required />

                </React.Fragment>
            );
        }
        else
        {
            form.push(
                <React.Fragment key={key}>
                    <label htmlFor={'space_name'+(1+i)} className="form-label-small">{UtilTranslate(props.translations_data, 'space_name', props.debug)} {1+i}</label>
                    <input
                        className="form-text"
                        type="text"
                        id={'space_name'+(1+i)}
                        maxLength="128"
                        value={nulltoblank(configurator_space_name[i])}
                        onChange={event => {
                            let space_name = [...configurator_space_name];
                            space_name[i] = event.target.value;

                            configurator_set_space_name(space_name);
                        }} />


                </React.Fragment>
            );
        }

        key++;

        // Always keep minimum 3 inputs
        if (i >= 2)
        {
            // Break if the input is higher than the last index with input
            if (i > index_last)
                break;
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'space_title', props.debug)} />

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'spaces_which', props.debug)}
                                </div>

                                { content_data !== null &&
                                    <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                                }

                                <form onSubmit={event => handleSubmit(event)}>
                                    {form}

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionStart(props)
{
    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        navigate('/plaatsing');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'start',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'start_title', props.debug)} />
                                
                                <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_start', props.debug)}</span></button>
                                </form>

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'start_footer_title', props.debug)}
                                </div>

                                <div className="content-bulletpoints">
                                    <div className="content-bulletpoint-container">
                                        <div className="content-bulletpoint-icon-info"></div>
                                        <div className="content-bulletpoint-text">
                                            {UtilTranslate(props.translations_data, 'start_bulletpoint_info', props.debug)}
                                        </div>
                                    </div>

                                    <div className="content-bulletpoint-container">
                                        <div className="content-bulletpoint-icon-custom"></div>
                                        <div className="content-bulletpoint-text">
                                            {UtilTranslate(props.translations_data, 'start_bulletpoint_custom', props.debug)}
                                        </div>
                                    </div>

                                    <div className="content-bulletpoint-container">
                                        <div className="content-bulletpoint-icon-fast"></div>
                                        <div className="content-bulletpoint-text">
                                            {UtilTranslate(props.translations_data, 'start_bulletpoint_fast', props.debug)}
                                        </div>
                                    </div>
                                </div>

                                <div className="content-footer">
                                    <div className="content-footer-icon"></div>
                                    {UtilTranslate(props.translations_data, 'start_footer', props.debug)}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_images
function SectionThankyou(props)
{
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'thankyou',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Crosssell
    const [crosssell_data, crosssell_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/crosssell');
                // <list> crosssells
                // <list> <str> title
                // <list> <str> url
                // <list> <str> image_photo

                crosssell_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    let crosssell = [];
    if (crosssell_data !== null)
    {
        for (let i = 0; i < crosssell_data.length; i++)
        {
            crosssell.push(
                <React.Fragment key={i}>
                    <Link to={crosssell_data[i][1]} target="_blank">
                        <div className="content-crosssell-subcontainer" style={{backgroundImage: 'url('+props.upload_images+'/'+crosssell_data[i][2]+')'}}>
                            <div className="content-crosssell-text">
                                {crosssell_data[i][0]}
                            </div>

                            <div className="content-crosssell-chevron"></div>
                        </div>
                    </Link>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'thankyou_title', props.debug)} />

                                <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <div className="content-crosssell-container">
                                    <div className="form-label">{UtilTranslate(props.translations_data, 'thankyou_crosssell', props.debug)}</div>

                                    {crosssell}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionType(props)
{
    // Configurator
    const [configurator_type, configurator_set_type] = useState(loadstate('configurator_type'));

    useEffect(() => {
        savestate('configurator_type', configurator_type);
    }, [
        configurator_type
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'type', configurator_type);

        navigate('/ruimtes');
    }

    // Options 2 (type)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/option2_type');
                // <str> option title
                // <int> option value
                // <str> option icon

                options_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_type === null)
                    configurator_set_type(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_type]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-tile';
            if (configurator_type === options_data[i][1])
            {
                buttonclass = 'form-radio-tile form-radio-tile-selected';
            }

            options.push(
                <React.Fragment key={i}>
                    <input
                        type="radio"
                        className="form-radio-hidden"
                        id={options_data[i][1]}
                        name="type"
                        value={options_data[i][1]}
                        checked={configurator_type === options_data[i][1]}
                        onChange={event => {
                            configurator_set_type(event.target.value);
                        }} />
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}
                        style={{flexBasis: Math.floor(100/options_data.length)+'%'}}>
                        <div className="form-radio-tile-icon" style={{backgroundImage: 'url('+props.upload_icons+'/'+options_data[i][2]+')'}}>
                        </div>
                        <div className="form-radio-tile-label noselect">
                            <div className="form-radio-tile-label-text">
                                {options_data[i][0]}
                            </div>
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'type_title', props.debug)} />

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'type_type', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-tile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionUnitExternal(props)
{
    // Configurator
    const [configurator_unitexternal, configurator_set_unitexternal] = useState(loadstate('configurator_unitexternal'));

    useEffect(() => {
        savestate('configurator_unitexternal', configurator_unitexternal);
    }, [
        configurator_unitexternal
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        
        //navigate('/extras');
        navigate('/contact');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'unitexternal',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedairconditioners/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'unitexternal_title', props.debug)} />

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'unitexternal_preference', props.debug)}
                                </div>

                                <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <label htmlFor="unitexternal" className="form-label-small">{UtilTranslate(props.translations_data, 'unitexternal_description', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <textarea
                                        className="form-textarea"
                                        id="unitexternal"
                                        value={nulltoblank(configurator_unitexternal)}
                                        onChange={event => {
                                            configurator_set_unitexternal(event.target.value);
                                        }}
                                        rows="10"
                                        required >
                                    </textarea>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export { SectionAirco, SectionArea, SectionCalendar, SectionColor, SectionContact, SectionInsulation, SectionNavigation, SectionOverview, SectionPrevious, SectionSpace, SectionStart, SectionThankyou, SectionType, SectionUnitExternal }